import { useEffect } from "react";

const RedirectPage = () => {
  useEffect(() => {
    function redirectToApp() {
      const userAgent = navigator.userAgent.toLowerCase();

      if (userAgent.includes("android")) {
        window.location.href = "https://play.google.com/store/apps/details?id=com.ardent.ardent_sports";
      } else if (/iphone|ipad|ipod/.test(userAgent)) {
        window.location.href = "https://apps.apple.com/us/app/ardent-sport/id6446237100?platform=iphone";
      } else {
        window.location.href = "https://play.google.com/store/apps/details?id=com.ardent.ardent_sports"; // Optional fallback
      }
    }

    redirectToApp();
  }, []);

  return <p>Redirecting...</p>;
};

export default RedirectPage;
